<template>
    <div id="mainheader">
    <header id="header" :class="{'headroom--unpinned': scrolled, 'headroom': isScrolled, 'isTopHeader': isTopHeader }">
        <div class="box-call-center">
          <div class="container-fluid">
            <div class="call-center d-flex justify-content-between">
              <div class="item-call">
                <img src="../../assets/images/tel-phone-icon.png" alt="icon">
                <span>Колл-центр:</span>
              </div>
              <div class="item-call">
                <a href="tel:712000013" class="a-dec-none">+998 71-200-00-13</a>
              </div>
            </div>
          </div>
        </div>

        <div class="container-navbar">
            <div class="container">
               <div class="nav-block">
                  <div class="logo-navbar d-flex justify-content-between align-items-center">
                    <b-icon icon="list" @click="showMobileMenu" class="bi-list-icon"></b-icon>
                    <div class="dropdown">
                     <button class="btn-vector" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                      <img src="../../assets/images/Logo servises/Vector.png" alt="Vector" class="Vector">
                     </button>

                     <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                         <div class="row">
                           <div class="col-lg-6">
                         <li class="InVector-item">
                           <a href="https://edo.faktura.uz/" target="_blank">
                           <img src="../../assets/images/edo.png" alt="png" class="vector-in-img">
                           <p class="InVector-description">{{translation.translate("LandingPageHome", "InVector-edo")}}</p>
                           </a>
                           </li>
                             </div>
                            <div class="col-lg-6">
                              <li class="InVector-item">
                                <a href="https://pay.faktura.uz/" target="_blank">
                                <img src="../../assets/images/pay.png" alt="png" class="vector-in-img">
                                <p class="InVector-description">{{translation.translate("LandingPageHome", "InVector-pay")}}</p>
                                </a>
                                </li>
                            </div>
                             <div class="col-lg-6">
                         <li class="InVector-item">
                           <a href="https://botcrm.faktura.uz/" target="_blank">
                           <img src="../../assets/images/crm.png" alt="png" class="vector-in-img">
                           <p class="InVector-description">{{translation.translate("LandingPageHome", "InVector-crm")}}</p>
                           </a>
                           </li>
                             </div>
                             <div class="col-lg-6">
                              <li class="InVector-item">
                                <a href="https://edi.faktura.uz/" target="_blank">
                                <img src="../../assets/images/EDI-logo.png" alt="" class="vector-in-img">
                                <p class="InVector-description">{{translation.translate("LandingPageHome", "InVector-edi")}}</p>
                                </a>
                                </li>
                            </div>
                         </div>
                     </ul>

                      </div>
                    <router-link :to="{ name: 'LandingPage'}"><img src="../../assets/images/Logo servises/logo.png" alt="logo" class="logo"></router-link>
                  </div>

                  <div class="navbar-box d-flex align-items-center justify-content-between">  
                    <ul class="box-menu" style="width: 320px">
                      <li class="nav-item"><a href="#newPrice">{{translation.translate("LandingPageHome", "priceNavbar")}}</a></li>
                      <li @click="showModal = true" class="nav-item">{{translation.translate("newUsers", "support")}}</li>
                      <select @change="_onChangeLanguage" v-model="localLanguage" class="change-select">
                          <option value="ru">Ру</option>
                          <option value="uz">Ўз</option>
                          <option value="oz">O'z</option>
                      </select>
                    </ul>

                    <a @click="showModal = true" class="a-dec-none ml-20"><button class="btn-sign-in">{{translation.translate("LandingPageHome", "toPlug")}}</button></a>
                  </div>
                </div>
                
            </div>
          </div>
    </header>

  <transition name="fade" v-if="showModal">
   <div id="modal-call">
        <div class="modal-dialog">
            <div class="modal-content">
             <div class="modal-header">
                    <button type="button" class="btn-close" @click="showModal = false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" fill="currentColor" class="bi bi-x" viewBox="0 0 20 20">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Заявка на подключение</h5>
                </div>
                <div class="modal-body">
                    <div>
                        <input type="text" v-model="message" class="form-call" placeholder="Имя">
                        <input type="text" v-model="callback" class="form-call" placeholder="Телефон номер">
                    </div>
                    <div class="box-callbutton">
                        <a @click.prevent="sendCallback" class="button-call">ПЕРЕЗВОНИТЬ</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>

    </div>
    
</template>

<script defer>
import translate from '../../../translation/translate';
import TelegramService from '../modal/services/telegram-service'
import mixins from "../../mixin";
export default {
  name: 'mainheader',
  data() {
    return {
      showModal: false,
      message: "",
      callback: null,
      mobileMenu: false,
      isScrolled: false,
      scrolled: false,
      scrollPosition: null,
      limitPosition: 500,
      lastPosition: 0,
      localLanguage: JSON.parse(localStorage.getItem("translation"))?.localeName || "uz",
      countForSlider: 0,
      translation: translate,
    }
  },
  mixins: [mixins],
  methods: {
        _onChangeLanguage(event) {
            translate.changeLocale(event.target.value);
            this.$emit("changeLang", event.target.value)
        },
        showMobileMenu() {
            this.mobileMenu = !this.mobileMenu
        },
        updateScroll() {
            /* eslint-disable no-debugger */
            this.scrollPosition = window.scrollY
            
            if (this.scrollPosition > 10) {
                this.isScrolled = true;
            } else {
                this.isScrolled = false;
            }
            
            if (this.scrollPosition < 20) {
                this.isTopHeader = true;
            } else  {
                this.isTopHeader = false;
            }
        },

        handleScroll() {
            if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
            this.scrolled = true;
            // move up!
            }

            if (this.lastPosition > window.scrollY) {
            this.scrolled = false;
            // move down
            }

            this.lastPosition = window.scrollY;
            // this.scrolled = window.scrollY > 250;
        },
        async sendCallback() {
            let text = `Клиент оставиль заявку на подробную консультацию. Имя ${this.message} Телефон номер: ${this.callback}`
            if (this.message, this.callback) {
                TelegramService.sendMessage(text)
                this.showModal = false
                this.$router.push('/newUsers/toThankPage'); 
                setTimeout(() => {
                    alert("Ваша заявка успешно отправлена!")
                }, 100)
            } else {
                alert('Заполните таблицу');
            }
        },
  },
  mounted() {
      window.addEventListener('scroll', this.updateScroll);
      setInterval(() =>{
        if(500 < this.count){
          document.querySelector("html body div#app section div.block-partners div.container.slider-ct div.row.slide-wr").style.transform = `translateX(${this.count++}%)`
          console.log(true)
        } 
      }, 40)
    },
  created() {
      this.$emit("changeLang", JSON.parse(localStorage.getItem("translation")).localeName)
      if (window.scrollY == 0) {
          this.isTopHeader = true;
      } else  {
          this.isTopHeader = false;
      }
      window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
  }
}

</script>

<style scoped>

a{
  text-decoration: none !important;
  list-style: none;
  font-size: 15px;
  color: #888888;
  font-weight: 500;
  transition: 300ms;
}

a:hover {
  color: #72bd49;
  font-weight: 500;
  cursor: pointer;
}

/* ========== ApplicationForConnection components css ==========*/

#modal-call {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    background: rgba(0, 0, 0, 0.295);
    left: 0;
    z-index: 100000000000000;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */
    {
    opacity: 0;
}

.green-span {
    color: #72bd49;
}

.modal-dialog {
    max-width: 490px !important;
}

.modal-header {
    border-bottom: none;
    justify-content: center;
    padding: 0;
}

.modal-content {
    position: relative !important;
    width: 743px;
    height: 400px;
    margin: 150px 0 0 auto !important;
    background: #fff;
    padding: 50px 30px !important;
}

.modal-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    padding: 32px 0 0;
    color: #676767;
    text-align: center;
    text-transform: uppercase;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    width: 30px;
    height: 25px;
    border-radius: 0 5px 0 0;
    border: none;
    margin: 0 0 0 auto !important;
    background: #D0D0D0;
    transition: 300ms;
}
.btn-close:hover{
    background: #c1c1c1;
}

.btn-close svg {
    color: white;
}

.modal-dialog .button-call {
    background: #72bd49;
    border-radius: 5px;
    font-weight: 500;
    color: #fff;
    padding: 12px 20px;
    cursor: pointer;
    text-decoration: none;
    transition: 300ms;
}

.modal-dialog .button-call:hover {
    box-shadow: 0 0 10px #72bd49;
}
.modal-dialog .box-callbutton {
    margin-top: 20px;
}

.modal-dialog .form-call {
    width: 384px;
    margin: 10px auto;
    background: white;
    border-radius: 5px;
    border: 1px solid #DBDBDB;
    font-weight: 500;
    color: #5A5A5A;
    padding: 10px 20px;
}

.modal-dialog .form-call::placeholder {
    color: #DBDBDB !important;
}

@media (max-width: 992px) {

    .modal-dialog {
        max-width: 490px;
    }

    .modal-content {
        width: 543px;
        height: 248px;
    }

    .modal-title {
        font-size: 18px;
        line-height: 22px;
    }

    .modal-dialog .form-call {
        width: 250px;
    }
}

@media (max-width: 576px) {
    .modal-body {
        padding: 0 0 20px 0;
    }

    .modal-dialog {
        max-width: 320px;
        margin: auto;
    }

    .modal-content {
        width: 310px;
        height: 180px;
    }

    .modal-title {
        font-size: 12px;
        line-height: 16px;
        padding: 20px;
    }

    .modal-dialog .form-call {
        width: 150px;
        padding: 5px 15px !important;
    }

    .modal-dialog .form-call::placeholder {
        font-size: 11px;
    }

    .modal-dialog .button-call {
        width: 80px !important;
        font-size: 14px;
        margin-left: 5px;
        padding: 9px 15px;
    }

    .btn-close {
        opacity: 1;
        width: 20px;
        height: 18px;
    }

    .btn-close svg {
        width: 30px;
        height: 20px;
    }
}
</style>
