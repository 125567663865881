<template>
<div id="thankPage">
    <headerAndModal></headerAndModal>
    <mainbox></mainbox>
    <section class="thankPage">
        <div class="container">
            <div class="content-box">
                <div class="content-img">
                    <img src="../../assets/images/email-thankPage.png" alt="email-png">
                </div>
                <div class="texts">
                    <h1>{{ translation.translate("newUsers", "titleThank") }}</h1>
                    <p>{{ translation.translate("newUsers", "infoThank") }}</p>
                    <a href="/" class="home-button">{{ translation.translate("newUsers", "buttonHome") }}</a>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script scoped>
import translate from "../../../translation/translate";
import mainbox from "../mainbox.vue";
import headerAndModal from "../newUsers/headerAndModal.vue";

export default {
    name: "thankPage",
    data() {
        return {
            title: "dalban",
            translation: translate,
        };
    },
    components: {
        headerAndModal,
        mainbox,
    },
    metaInfo() {
        return {
        title: "Спасибо за Вашу заявку!",
        meta: [
        {
            name: "description",
            content:
            "Мы свяжемся с Вами в ближайшее время",
        },
      ],
    };
  },
};
</script>

<style>
.thankPage .content-box{
    margin-top: 15%;
}
.thankPage .content-box .content-img {
    display: flex;
    justify-content: center;
}

.thankPage .content-box .texts h1 {
    padding: 0;
    margin-top: 15px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #3A3A3A;
}
.thankPage .content-box .texts p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #7E7E7E;
    text-align: center;
    margin: 15px auto 10px;
}
.thankPage .content-box .texts .home-button {
    width: 139px;
    height: 40px;
    display: flex;
    justify-content: center;
    margin: auto;
    background: linear-gradient(90deg, #72BD49 0%, #51DF00 96.12%);
    border-radius: 50px;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
    transition: 300ms;
}
.thankPage .content-box .texts .home-button:hover{
    box-shadow: 0 0 5px #51DF00;
}
</style>
